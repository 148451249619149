import "./output.css";
import { useState, useEffect } from "react";
import { Popup } from "react-map-gl";
import Spinner from "./Spinner";

import psFull from "./asi-ps-full.png";
import psMs from "./asi-ps-ms.png";
import psProvisional from "./asi-ps-provisional.png";
import coc from "./asi-coc.png";

var certificationLogo;
var companyLogo;

export default function AsiPopup(props) {
    // Define state
    const [isLoading, setIsLoading] = useState(true);

    // Text content:
    // If the facility is in Australia, the US, Canada or China, display the province as well.
    // TODO: Extend to other countries?

    let countriesWithProvinces = [
        "United States",
        "Australia",
        "Canada",
        "China",
        "Brazil",
    ];

    var locationText;

    if (
        countriesWithProvinces.includes(props.data["COUNTRY"]) &&
        props.data["PROVINCE"]
    )
        locationText = `${props.data["CITY"]}, ${props.data["PROVINCE"]}, ${props.data["COUNTRY"]}`;
    else locationText = `${props.data["CITY"]}, ${props.data["COUNTRY"]}`;

    switch (props.data.STANDARD) {
        case "Performance Standard":
            if (props.data.IS_PROVISIONAL) certificationLogo = psProvisional;
            else if (props.data.MATERIAL_STEWARDSHIP) certificationLogo = psMs;
            else certificationLogo = psFull;
            break;
        case "Chain of Custody Standard":
            certificationLogo = coc;
            break;
        default:
            certificationLogo = null;
            break;
    }

    useEffect(() => {
        const img = new Image();
        img.src = props.data["LOGO"] ? props.data["LOGO"] : "";

        if (img.complete) setIsLoading(false);
        else {
            setIsLoading(true);
            img.onload = () => {
                setIsLoading(false);
            };
        }
    }, [props.data]);

    return (
        <Popup
            closeOnClick={false}
            latitude={props.data["LATITUDE"]}
            longitude={props.data["LONGITUDE"]}
            offset={30}
            maxWidth="440px"
        >
            {isLoading ? (
                <div className="w-[440px] h-full my-12 text-asi-green">
                    <Spinner />
                </div>
            ) : (
                ""
            )}
            <div className={isLoading ? "hidden" : ""}>
                <div className="z-10 bg-dark-grey w-full flex flex-auto items-center p-4 gap-4 rounded-t-md">
                    {props.data["LOGO"] && (
                        <div className="bg-light-grey w-1/3 rounded-md p-2">
                            <img
                                className="max-h-32 mx-auto"
                                src={props.data["LOGO"]}
                                onLoad={() => setIsLoading(false)}
                                alt="Placeholder"
                            />
                        </div>
                    )}
                    <div className={props.data["LOGO"] ? "w-2/3" : ""}>
                        <div className="text-lg font-bold text-asi-green line-clamp-3">
                            {props.data["FACILITY_NAME"]}
                        </div>
                        <div className="text-md text-light-grey font-medium line-clamp-2">
                            {props.data["URL"] ? (
                                <a
                                    className="link-to-member-page"
                                    href={props.data["URL"]}
                                >
                                    {props.data.ASI_MEMBER}
                                    <i className="ml-1 ri-external-link-line" />
                                </a>
                            ) : (
                                <div className="font-bold">
                                    {props.data["ASI_MEMBER"]}
                                </div>
                            )}
                        </div>
                        <div className="text-md text-light-grey">
                            {locationText}
                        </div>
                    </div>
                </div>
                <div className="w-full flex flex-auto justify-items-stretch items-center p-4 gap-4">
                    <div className="font0-bold text-4xl w-1/3 rounded-bl-md items-end text-center">
                        <img
                            className="max-h-32 mx-auto"
                            src={certificationLogo}
                            alt="Placeholder"
                        />
                    </div>
                    <div className="w-2/3">
                        <div className="font-medium">
                            ASI {props.data["STANDARD"]} Certified {props.data["IS_PROVISIONAL"] ? "(Provisional Certification)" : ""}
                        </div>
                        <div>{props.data["STANDARD_REVISION"]}</div>
                        <div>Certificate {props.data["CERTIFICATE"]}</div>
                    </div>
                </div>
            </div>
        </Popup>
    );
}
