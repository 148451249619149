import './App.css';
import './output.css'
import MapComponent from "./Map";
import 'remixicon/fonts/remixicon.css'

function App() {
    return (
        <MapComponent/>
    );
}

export default App;
