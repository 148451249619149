/**
 * Map component, utilising the `react-map-gl` library
 *
 * @author  Beau Nieuwveld
 * @created 2023-04-13
 */

import * as React from "react";
import {useEffect, useRef, useState} from "react";
import Map, {Layer, Source} from "react-map-gl";
import "./Map.css";
import {clusterCircles, clusterCount, dataLayer} from "./mapStyles";
import mapboxgl from "mapbox-gl";
import AsiPopup from "./AsiPopup";
import Spinner from "./Spinner";

// "npm run build" fails if you don't include this... booooo
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const TRANSITION_TIME = 1500;

export default function MapComponent() {
    const [allData, setAllData] = useState(null);
    const [selectedPoint, setSelectedPoint] = useState(null);
    const [cursor, setCursor] = useState("auto");
    const mapRef = useRef();

    const MAPBOX_TOKEN =
        "pk.eyJ1IjoiaXRzYmVhdSIsImEiOiJjbDZrODE5NDYxYmV3M2pycDd3ZTFsN3htIn0.Pz6oSZcGuGrLV2UknR30Jw";

    // When the map is loaded, get the GeoJSON from the API that contains all the relevant details
    useEffect(() => {
        fetch(
            "https://intellectual-bumblebee-dev-wfesmobwfa-ew.a.run.app/latest/cert-map/geojson"
        )
            .then((resp) => resp.json())
            .then((json) => offsetData(json))
            .catch((err) => console.error("Couldn't load data", err));
    }, []);


    // On click, what's at the selected area?
    function onClick(e) {
        let latitude = e.lngLat.lat;
        let longitude = e.lngLat.lng;
        setSelectedPoint(null);

        // If no features are present, do nothing.
        if (e.features.length === 0) return;
        else var feature = e.features[0];

        if (feature.layer.id === "features")
            setSelectedPoint(feature.properties);

        if (feature.layer.id === "clusters") {
            let clusterSource = mapRef.current.getSource("allFeatures");
            clusterSource.getClusterExpansionZoom(
                feature.properties.cluster_id,
                (err, zoom) => {
                    if (err) return;
                    mapRef.current.flyTo({
                        center: feature.geometry.coordinates,
                        zoom: zoom + 1,
                        duration: TRANSITION_TIME,
                    });
                }
            );
        }
    }

    function calculateOffsets(count) {
        // This function calculates a unique offset for each point in a cluster.
        var offsets = [];
        for (let i = 0; i < count; i++) {
            // This places the points in a circle around the original position.
            // You could calculate different offsets depending on your requirements.
            var angle = (i / count) * Math.PI * 2;
            var dx = Math.cos(angle);
            var dy = Math.sin(angle);
            offsets.push([dx, dy]);
        }
        return offsets;
    }


    function offsetData(data) {
        let offset = data.features.map((feature, i) => {
            feature.geometry.coordinates[0] += Math.cos(Math.random() * Math.PI * 2) * 0.0002
            feature.geometry.coordinates[1] += Math.sin(Math.random() * Math.PI * 2) * 0.0002
            return feature
        })

        data = {
            ...data,
            features: offset
        }

        setAllData(data)
    }

    function onMouseEnter(e) {
        // Cursor should be 👆 if hovering over a feature, otherwise default ⬆
        setCursor(e.features.length > 0 ? "pointer" : "auto");
    }

    function onMouseLeave(e) {
        setCursor("auto")
    }

    // If the data hasn't loaded yet, return a Loading... screen
    if (!allData)
        return (
            <div className="text-2xl flex flex-auto w-screen h-screen">
                <div className="m-auto">
                    <Spinner/>
                </div>
            </div>
        );

    return (
        <div className="map-component select-none">
            <Map
                initialViewState={{
                    longitude: 20,
                    latitude: 35,
                    zoom: 1.5,
                }}
                mapStyle="mapbox://styles/itsbeau/clewdy29k000701mgidb51p7q"
                mapboxAccessToken={MAPBOX_TOKEN}
                dragRotate={false}
                touchZoomRotate={false}
                touchPitch={false}
                cursor={cursor}
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                interactiveLayerIds={["features", "clusters"]}
                interactive={true}
                ref={mapRef}
            >
                <Source
                    id="allFeatures"
                    type="geojson"
                    data={allData}
                    cluster={true}
                    clusterMaxZoom={9}
                    clusterRadius={24}
                >
                    <Layer {...dataLayer} />
                    <Layer {...clusterCircles} />
                    <Layer {...clusterCount} />

                    {selectedPoint && <AsiPopup data={selectedPoint}/>}
                </Source>
            </Map>
        </div>
    );
}
