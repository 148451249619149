/*
Pseudocode for the `icon-image` expression:

if MATERIAL_STEWARDSHIP == true {
    if STANDARD == "Performance Standard" {

    }
}

 */

export const dataLayer = {
    id: "features", source: "facilities", type: "symbol", filter: ['!', ['has', 'point_count']], layout: {
        "icon-allow-overlap": true,
        "icon-image": ["case", ["get", "MATERIAL_STEWARDSHIP"], ["match", ['get', 'STANDARD'], 'Performance Standard', ['match', ['get', 'FACILITY_TYPE'], 'Mine', 'ps_ms_mine', 'Facility', 'ps_ms_facility', 'Office', 'ps_ms_office', 'null',], 'Chain of Custody Standard', ['match', ['get', 'FACILITY_TYPE'], 'Mine', 'coc_mine', 'Facility', 'coc_facility', 'Office', 'coc_office', 'null',], 'null'], ["match", ['get', 'STANDARD'], 'Performance Standard', ['match', ['get', 'FACILITY_TYPE'], 'Mine', 'ps_mine', 'Facility', 'ps_facility', 'Office', 'ps_office', 'null',], 'Chain of Custody Standard', ['match', ['get', 'FACILITY_TYPE'], 'Mine', 'coc_mine', 'Facility', 'coc_facility', 'Office', 'coc_office', 'null',], 'null']],
        "icon-size": ['interpolate', ['linear'], ['zoom'], 4, 0.3, 16, 0.7], // "text-offset": [1.5, 1.5],
        "text-max-width": 10,
        "text-variable-anchor": ["left", "top", "right", "bottom"],
        "text-justify": "auto",
        "text-field": ['get', 'FACILITY_NAME'],
        "text-font": ["Poppins SemiBold"],
        "text-padding": ['interpolate', ['linear'], ['zoom'], 4, 16, 16, 32],
        "text-optional": true,
        "text-size": ['interpolate', ['linear'], ['zoom'], 4, 10, 16, 16],
        "text-line-height": 1,
        "text-letter-spacing": ['interpolate', ['linear'], ['zoom'], 4, -0.02, 16, 0.02],
    }, paint: {
        "text-color": "#efefef",
        "text-halo-color": "#333740", "text-halo-blur": 0,
    }
}


export const clusterCircles = {
    id: 'clusters', type: 'circle', source: 'facilities', filter: ['has', 'point_count'], paint: {
        'circle-color': ['interpolate', ['linear'], ['get', 'point_count'], 0, '#DEECCA', 6, '#8DC63F', 10, '#3F723B', 14, '#364537'],
        'circle-radius': ['interpolate', ['linear'], ['get', 'point_count'], 0, 7, 10, 20],
        'circle-stroke-width': 0.5,
        'circle-stroke-color': '#efefef'
    }
}

export const clusterCount = {
    id: 'cluster-count', type: 'symbol', source: 'facilities', filter: ['has', 'point_count'], layout: {
        'text-allow-overlap': true,
        'text-field': ['get', 'point_count_abbreviated'],
        'text-font': ['Poppins Bold'],
        'text-size': ['interpolate', ['linear'], ['get', 'point_count_abbreviated'], 2, 10, 16, 16]
    }, paint: {
        'text-color': ['step', ['get', 'point_count_abbreviated'], '#3F723B', 7, '#DEECCA',],
    }
}